/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.11 dice-blendswap.glb --transform
*/

import React, { useRef, useEffect, useState } from 'react'
import { Html, useGLTF } from '@react-three/drei'
import { useSpring, a } from '@react-spring/three'
import { useFrame } from '@react-three/fiber';

export default function Yahtzee(props) {
  const [hovered, setHovered] = useState(false)

  const { nodes, materials } = useGLTF('/dice-blendswap-transformed.glb')

  const ref = React.useRef();

  useFrame((state) => {
    const t = state.clock.getElapsedTime()

    ref.current.rotation.z = -0.2 - (1 + Math.sin(t / 1.5)) 
    ref.current.rotation.x = Math.cos(t / 4) / 8
    ref.current.rotation.y = Math.sin(t / 4) / 8
    ref.current.rotation.y = (1 + Math.sin(t / 1.5))
  })

  console.log(ref)

  // ref.current.children[0].material.color.set(0xffff00)
  // ref.current.children[0].material.color.set(0xffffff)

  // Here lies my half-broken code to get an HTML popup when hovering.
  // It's laggy, and doesn't work well. But if I ever want to get this
  // fully working, it should be a good starting point. Put this in the
  // mesh next to the existing hover code.
  /*
        { hovered?
          <Html pointerEvents='none'>
            <div className="annotation">Hello world!</div>
          </Html>
        : <></>
        }
  */

  return (
    <group 
      {...props} 
      ref={ref}
      dispose={null}
      scale={[0.08, 0.08, 0.08]}
      position={[-0.8, 1.30, -0.30]}
      rotation={[0.5, 0.5, 0.5]}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      onClick={() => {window.open("https://mwaldrich.io/yahtzee/", '_blank');}}
      >
      <mesh geometry={nodes.dice_1.geometry} material={materials.dice}>
        // Set color to yellow if hovered
        { hovered?
          <meshStandardMaterial color={'yellow'} />
        : <></>
        }
      </mesh>
      <mesh geometry={nodes.dice_2.geometry} material={materials.dots} />
    </group>
  )
}

useGLTF.preload('/dice-blendswap-transformed.glb')
